.alert {
    position: absolute;
    padding: 15px;
    border-radius: 5px;
    opacity: 0;
    animation: fadeInOut 1s infinite;
    z-index: 99;
    left: 40%;
    top: 25px;
    color: rgba(255, 0, 0, 0.8);
    text-shadow: "0px 0px 20px black";
  }
  
  @keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}