body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(60,64,67);
  margin: 10px 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
}

.btn-middle {
  width: 56px !important;
  height: 56px !important;
}

.btn-middle svg {
  width: 1.2em !important;
  height: 1.2em !important;
}

.wrapAgora div {
  border-radius: 20px;
  margin: 10px;
  width: auto !important;
}

.wrapAgora video {
  position: relative;
}


.agora_video_player {
  /* object-fit: cover !important; */
  object-fit: contain !important;
  /* scale: 1.27 !important; */
  /* scale: 1.6 !important; */
  /* scale: 1.48 !important; */
  scale: 1.5 !important;
}
